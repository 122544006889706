export enum LicenseEnvironmentEnum {
  Internal = 1,
  Demo = 2,
  Paid = 3,
}
export class LicenseEnvironmentEnumHelper {
  static getOptions() {
    return {
      Internal: {
        label: 'Internal',
        value: 1,
      },
      Demo: {
        label: 'Demo',
        value: 2,
      },
      Paid: {
        label: 'Paid',
        value: 3,
      },
    }
  }
  static getDefaultOption() {
    return {
      label: 'Paid',
      value: 3,
    }
  }
}
