<template>
  <div>
    <p v-if="loading">Loading...</p>
    <p v-if="error">{{ error }}</p>
    <p v-if="success">Authentication successful!</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TornadoAuthCallback',
  data() {
    return {
      loading: true,
      error: null,
      success: false,
    };
  },
  async mounted() {
    try {
      let { type, tornadoBaseUrl, wails, encodedTokens } = this.parseUrlParams();
     
      wails = wails === 'true';  // convert to boolean
      if (wails) {
        tornadoBaseUrl = "wails://wails"
      }

      if (!tornadoBaseUrl) {
        this.redirectToAuth();
        return;
      }

      if (!encodedTokens) {
        throw new Error('Token information not found');
      }

      const setTokenBaseUrl = this.determineSetTokenBaseUrl(tornadoBaseUrl, wails);
      
      await this.setTokens(setTokenBaseUrl, encodedTokens, type);
      this.redirectToTornado(tornadoBaseUrl, type);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    parseUrlParams() {
      const queryString = window.location.hash.split('?')[1];
      const params = new URLSearchParams(queryString);
      return {
        type: params.get('type'),
        wails: params.get('wails'),
        tornadoBaseUrl: params.get('redirectUrl'),
        encodedTokens: params.get('tokens')
      };
    },

    redirectToAuth() {
      this.success = false;
      this.$router.push('/tornado/auth');
    },

    determineSetTokenBaseUrl(tornadoBaseUrl, wails) {
      if (wails) {
        return process.env.VUE_APP_TORNADO_WAILS_APP_URL;
      }
      const urlObj = new URL(tornadoBaseUrl);
      return `${urlObj.protocol}//${urlObj.host}`;
    },

    async setTokens(setTokenBaseUrl, tokens, type) {
      if (type === 'google') {
        const setTokenUrl = new URL('/api/gmail/personal/auth/tokens', setTokenBaseUrl);
        await axios.post(setTokenUrl.toString(), { tokens });
      } else if (type === 'o365') {
        const setTokenUrl = new URL('/api/o365/personal/auth/tokens', setTokenBaseUrl);
        await axios.post(setTokenUrl.toString(), { tokens });
      }
    },

    redirectToTornado(tornadoBaseUrl, type) {
      if (type === 'google') {
        const targetRedirectUrl = new URL('/parselets/gmail/', tornadoBaseUrl);
        window.location.href = targetRedirectUrl.toString();
      } else if (type === 'o365') {
        const targetRedirectUrl = new URL('/parselets/office-365/', tornadoBaseUrl);
        window.location.href = targetRedirectUrl.toString();
      }
    },

    handleError(error) {
      this.error = 'An error occurred: ' + error.message;
    }
  }
};
</script>
