<template>
  <el-container class="licenses-page">
    <el-header class="licenses-header">
      <div class="licenses-count">
        <h3 v-text="`${$t('Licenses')}`" />

        <el-select v-model="selectedEnvironment" placeholder="Select" @change="onChangeEnvironment">
          <el-option
            v-for="item in Object.values(environment)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <span>Last Updated At: {{ getFormattedDateWithTime(updateTime) }}</span>
        <span class="mr-1 reload_icon" @click="reloadLicenses()"><fa-icon icon="rotate" /></span>
      </div>
      <div class="licenses-actions">
        <el-button
          v-if="hasPermission('license-create')"
          type="primary"
          v-on:click="toggleNewLicenseDialog()"
          primary
          icon="el-icon-circle-plus-outline"
          >Create License
        </el-button>
        <filter-license-popover @queryReady="runFilter" :query="query" />
      </div>
    </el-header>
    <V-Card>
      <el-table
        :data="pagedTableData"
        stripe
        empty-text="There are no licenses"
        v-loading="$wait.is($waiters.License.Licenses)"
        height="77vh"
        class="w-100"
        cell-class-name="main-cell-class"
        header-cell-class-name="main-cell-class"
      >
        <el-table-column prop="key" min-width="260">
          <template slot="header">
            <span style="padding-right: 5px">License Key</span>
          </template>
          <template v-slot="scope">
            <a class="license-key-link" @click="clickToLink(scope.row.key)">{{ scope.row.key }}</a>
          </template>
        </el-table-column>

        <el-table-column prop="key" min-width="70">
          <template slot="header">
            <span style="padding-right: 5px">Version</span>
          </template>
          <template v-slot="scope">
            {{ scope.row.version }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="License Type" min-width="95">
          <template v-slot="scope">
            <div class="type-cell">
              {{ LicenseTypeEnum[scope.row.type] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="cap" label="Asset Info" :min-width="138">
          <template slot="header">
            <span style="padding-right: 5px">Asset Info</span>
            <el-popover placement="bottom-start" title="Asset Info Usage" width="20em" trigger="click">
              <div>
                <div>This field contains current quota usage information and quota usage statuses.</div>
                <div>(This information is not applicaple for IR licenses)</div>
                <div><b>Pending:</b> Quota is not active yet.</div>
                <div><b>Active:</b> There is an active quota.</div>
                <div><b>Full:</b> All Quotas have been spent (Asset count upper limit reached).</div>
                <div><b>Almost Full:</b> Quota is almost full!</div>
              </div>
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </template>
          <template v-slot="scope">
            <div class="capacity-cell">
              <div class="titled-text-cell-rows">
                <div class="capacity-cell-item-header"><b>Server</b>:</div>
                <div>
                  {{ capacityDisplay(scope.row.activeServerCapacity, scope.row.serversInUse, scope.row.type) }}
                </div>
              </div>
              <div class="titled-text-cell-rows">
                <div class="capacity-cell-item-header"><b>Assets</b>:</div>
                <div>
                  {{
                    capacityDisplay(scope.row.activeTotalClientCapacity, scope.row.totalClientsInUse, scope.row.type)
                  }}
                </div>
              </div>
            </div>
            <div
              class="status-cell"
              v-if="!['N/A', 'pending'].includes(scope.row.useStatus)"
              :class="scope.row.useStatus"
            >
              <span>{{ capitalize(scope.row.useStatus) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Lifetime Info" min-width="180">
          <template slot="header">
            <span style="padding-right: 5px">Lifetime Info</span>
            <el-popover placement="bottom-start" title="Lifetime Info" width="20em" trigger="click">
              <div>
                <div>This field contains current quota lifetime information and quota lifetime statuses.</div>
                <div><b>Pending:</b> There are no active quotas.</div>
                <div><b>Active:</b> There is an active quota.</div>
                <div><b>Expired:</b> All quotas have expired.</div>
                <div><b>Almost Expired:</b> All quotas will expire soon.</div>
              </div>
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </template>
          <template v-slot="scope">
            <div class="titled-text-cell-rows">
              <div style="width: 6em"><b>Start Date</b>:</div>
              <div>
                {{ getFormattedDate(scope.row.activatedAt) }}
              </div>
            </div>
            <div class="titled-text-cell-rows">
              <div style="width: 6em"><b>End Date</b>:</div>
              <div>
                {{ getFormattedDate(scope.row.expiredAt) }}
              </div>
            </div>
            <div class="status-cell" :class="scope.row.lifetimeStatus">
              <span>{{ capitalize(scope.row.lifetimeStatus) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Connection Info" min-width="200">
          <template slot="header">
            <span style="padding-right: 5px">Connection Info</span>
            <el-popover placement="bottom-start" title="Connection Info" width="20em" trigger="click">
              <div>
                <div>This field contains information about connection between AIR instances and License server.</div>
                <div><b>Active:</b> This license is actively installed and being used in AIR servers.</div>
                <div><b>Inactive:</b> This license is not being used.</div>
              </div>
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </template>
          <template v-slot="scope">
            <div class="titled-text-cell-rows" v-if="scope.row.type === 1">
              <div style="width: 7em"><b>First Contact</b>:</div>
              <div>
                {{ getFormattedDateWithTime(scope.row.firstHeartbeat) }}
              </div>
            </div>
            <div class="titled-text-cell-rows" v-if="scope.row.type === 1">
              <div style="width: 7em"><b>Last Contact</b>:</div>
              <div>
                {{ getFormattedDateWithTime(scope.row.lastHeartbeat) }}
              </div>
            </div>
            <div
              v-if="!['N/A'].includes(scope.row.activenessStatus)"
              class="status-cell"
              :class="scope.row.activenessStatus === 'active' ? 'active' : 'passive'"
            >
              <span>{{ capitalize(scope.row.activenessStatus) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="customer" label="Customer" min-width="300">
          <template v-slot="scope">
            <div class="type-cell">
              <div class="titled-text-cell-rows">
                <div class="customer-cell-item-header"><b>Name</b>:</div>
                <div>{{ scope.row.firstName + ' ' + scope.row.lastName }}</div>
              </div>
              <div class="titled-text-cell-rows">
                <div class="customer-cell-item-header"><b>Email</b>:</div>
                <div>{{ scope.row.email }}</div>
              </div>
              <div class="titled-text-cell-rows">
                <div class="customer-cell-item-header"><b>Company</b>:</div>
                <div>{{ scope.row.companyName }}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="Tags" min-width="200">
          <template v-slot="scope">
            <div class="tags-cell">
              <div class="tag-cell-elem" :key="index" v-for="(tag, index) in getIndividualTags(scope.row.tags)">
                <span class="tag-cell-span" @click="searchByThisLicense(tag.val)">{{ tag.val }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handlePaginationPageChange"
        @size-change="handlePaginationSizeChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100]"
        :total="totalEntityCount"
      >
      </el-pagination>
    </V-Card>
    <create-license-panel
      :panelDisplayStatus="newLicensePanelVisible"
      :environment="selectedEnvironment"
      @displayStatus="toggleNewLicenseDialog"
      @reload="onReload"
    />
    <license-details-panel
      :panelDisplayStatus="licenseDetailsPanelVisible"
      :detailKey="selectedLicenseKey"
      :environment="selectedEnvironment"
      @reload="onReload"
      @displayStatus="togglelicenseDetailsDialog"
    />
  </el-container>
</template>

<script>
import { LicensesService } from '@/api/Services/License/LicensesService'
import { LicenseTypeEnum } from '@/api/Services/License/enums/license-type.enum'
import FilterLicensePopover from '@/views/License/children/subComp/filterLicensePopover.vue'
import LicenseDetailsPanel from '@/views/License/children/subComp/licenseDetailsPanel.vue'
import { DateFormats, UtilityService } from '@/api/Services/License/UtilityService'
import { LicenseFilteredRequestDto } from '@/api/Services/License/dto/request/license-filtered-request.dto'
import CreateLicensePanel from '@/views/License/children/subComp/createLicensePanel.vue'
import { LicenseEnvironmentEnumHelper } from '@/api/Services/License/enums/license-environment.enum'

export default {
  name: 'Licenses',

  components: {
    LicenseDetailsPanel,
    CreateLicensePanel,
    FilterLicensePopover,
  },

  data() {
    const Environment = {
      Development: {
        label: 'Development',
        value: '1',
      },
      Staging: {
        label: 'Staging',
        value: '2',
      },
      Production: {
        label: 'Production',
        value: '3',
      },
    }

    return {
      disabled: true,
      tableData: [],
      totalEntityCount: 0,
      currentPage: 1,
      pageSize: 10,
      previousPage: 0,
      totalPageCount: 0,
      nextPage: 0,
      newLicensePanelVisible: false,
      licenseDetailsPanelVisible: false,
      selectedLicenseKey: '',
      selectedEnvironment: LicenseEnvironmentEnumHelper.getDefaultOption().value,
      environment: LicenseEnvironmentEnumHelper.getOptions(),
      query: {},
      updateTime: null,
    }
  },

  computed: {
    LicenseTypeEnum() {
      return LicenseTypeEnum
    },

    pagedTableData() {
      return this.tableData
    },
    fit() {
      return false
    },
  },

  methods: {
    handlePaginationPageChange(val) {
      this.fetchLicenses(this.query, { page: val, size: this.pageSize })
    },
    async reloadLicenses() {
      await LicensesService.reloadMaterializedView()
      this.fetchLicenses(this.query, { page: this.currentPage, size: this.pageSize })
    },

    handlePaginationSizeChange(val) {
      this.fetchLicenses(this.query, { page: this.currentPage, size: val })
    },

    runFilter(data) {
      this.query = data
      this.fetchLicenses(data, { page: 1, size: this.pageSize })
    },

    fetchLicenses(query, page) {
      this.$request(async () => {
        const req = this.buildFilterRequest(query, page)
        const { data } = await LicensesService.getLicensesSummary(req)
        if (data.success) {
          this.tableData = data.result.entities
          this.totalEntityCount = data.result.totalEntityCount
          this.currentPage = data.result.currentPage
          this.pageSize = data.result.pageSize
          this.previousPage = data.result.previousPage
          this.totalPageCount = data.result.totalPageCount
          this.fetchLastUpdateTime()
        }
      }, this.$waiters.License.Licenses)
    },

    fetchLastUpdateTime() {
      this.$request(async () => {
        const response = await LicensesService.fetchLastUpdateTime()
        if (response.data.success) {
          this.updateTime = response.data.result
        }
      })
    },

    toggleNewLicenseDialog() {
      this.newLicensePanelVisible = !this.newLicensePanelVisible
    },

    togglelicenseDetailsDialog() {
      this.licenseDetailsPanelVisible = !this.licenseDetailsPanelVisible
    },

    clickToLink(key) {
      this.selectedLicenseKey = key
      this.togglelicenseDetailsDialog()
    },

    getIndividualTags(tagstr) {
      if (tagstr) {
        let a = 0
        const tagsArry = tagstr.split(', ').map((tag) => {
          a++
          return { id: a, val: tag }
        })
        if (tagsArry.length > 0) {
          const lastElement = tagsArry[tagsArry.length - 1]
          lastElement.val = lastElement.val.slice(0, -1)
          tagsArry[tagsArry.length - 1] = lastElement
        }
        return tagsArry
      }
      return []
    },

    getFormattedDate(date) {
      let formattedDate = UtilityService.getFormattedDate(date, DateFormats.DATE)
      if (formattedDate === 'Invalid Date') formattedDate = '—'
      return formattedDate
    },

    getFormattedDateWithTime(date) {
      let formattedDate = UtilityService.getFormattedDate(date, DateFormats.MINUTES)
      if (formattedDate === 'Invalid Date') formattedDate = '—'
      return formattedDate
    },

    buildFilterRequest(query, page) {
      let request = query
      if (!query) {
        request = {}
      } else if (query.types) {
        request.types = query.types.map((p) => parseInt(p))
        if (query.tag === []) {
          query.tag = ''
        }
      }
      request.pageNumber = page.page
      request.pageSize = page.size
      request.environment = parseInt(this.selectedEnvironment)
      request.sortBy = 'createdAt'
      request.sortType = 'DESC'
      return request
    },

    mergePredefinedFilterWithParams() {
      const filter = this.$route.params.filter
      if (filter) {
        const preparedFilter = new LicenseFilteredRequestDto()
        if (filter?.tag) {
          preparedFilter.tag = filter.tag
        }

        return preparedFilter
      }
      return {}
    },

    onReload() {
      this.fetchLicenses(this.query, { page: this.currentPage, size: this.pageSize })
    },

    onChangeEnvironment() {
      this.fetchLicenses(this.query, { page: 1, size: this.pageSize })
    },

    searchByThisLicense(tag) {
      const preparedFilter = new LicenseFilteredRequestDto()
      preparedFilter.tag = tag
      this.query = preparedFilter
      this.fetchLicenses(this.query, { page: 1, size: this.pageSize })
    },

    AcitivenessStatusText(activeness) {
      if (activeness.lowerCase() === 'active') {
        return 'Live'
      } else {
        return this.capitalize(activeness)
      }
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    capacityDisplay(totalCapacity, currentUsage, type) {
      if (type === 1) {
        return currentUsage + '/' + totalCapacity
      } else {
        return currentUsage + '/∞'
      }
    },
  },

  mounted() {
    const filter = this.mergePredefinedFilterWithParams()
    this.query = filter
    this.fetchLicenses(filter, { page: 1, size: this.pageSize })
  },
}
</script>

<style lang="scss">
.reload_icon {
  cursor: pointer;
  transition: color 0.3s;
}

.reload_icon:hover {
  color: #007bff;
}
.license-key-link {
  text-decoration: underline;
  transition: color 0.3s;
}
.license-key-link:hover {
  color: #007bff; /* Change text color on hover */
}
.licenses-header {
  height: auto !important;
  padding: 0 2px !important;
  margin: 0 0 10px 0 !important;
  display: flex !important;
  align-items: baseline;
}

.licenses-count {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}

.licenses-actions > button {
  height: 40px;
  margin: 0px;
}

.capacity-cell {
  display: flex;
  flex-direction: column;
}
.type-cell {
  display: flex;
  flex-direction: column;
}
.tags-cell {
  display: flex;
  flex-wrap: wrap;
}
.date-cell {
  display: flex;
  flex-direction: column;
}
.tag-cell-elem {
  display: inline-block;
  text-align: center;
}

.tag-cell-span {
  background-color: $color-blue-20;
  width: 2.4em;
  height: 2.4em;
  line-height: 2.4em;
  border-radius: 5px;
  margin: 0 2px;
  padding: 2px 5px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.tag-cell-span:hover {
  background-color: $color-blue-40;
  cursor: pointer;
}
.tag-cell-span:active {
  background-color: $color-blue-60;
}
.titled-text-cell-rows {
  display: flex;
}
.date-cell-item-header {
  width: 98px;
}
.customer-cell-item-header {
  width: 74px;
}
.capacity-cell-item-header {
  width: 55px;
}
.status-cell {
  font-size: 0.9em;
  line-height: 1.5em;
  border-radius: 5px;
  margin: 0 2px;
  padding: 0px 5px;
  justify-content: center;
  display: flex;
  margin: 5px;
}
.passive {
  background-color: $color-dark-20;
}
.started {
  background-color: $color-green;
  color: white;
}
.activated {
  background-color: $color-blue;
  color: white;
}
.expired {
  background-color: $color-red;
  color: white;
}
.pending {
  background-color: $color-dark-20;
}
.almost-expired {
  background-color: $color-orange;
  color: white;
}
.full {
  background-color: $color-red;
  color: white;
}
.active {
  background-color: $color-green;
  color: white;
}
.almost-full {
  background-color: $color-orange;
  color: white;
}

.main-cell-class {
  font-size: 0.9em;
  * {
    font-size: inherit;
  }
}
</style>
