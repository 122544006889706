<template>
  <el-popover placement="top" width="600" v-model="visible">
    <div class="license-filter-header">
      <span class="el-dialog__title license-filter-header-text">Filter</span>
    </div>

    <div class="filter-query-grid">
      <el-select
        v-model="filterData.mainQueryField"
        filterable
        placeholder="Please enter a tag"
        name="tags"
        class="license-filter-input-element"
        size="small"
      >
        <el-option
          v-for="item in mainFilterOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>
      <el-input
        :placeholder="getSearchPlaceHolder()"
        v-model="filterData.mainQuery"
        class="license-filter-input-element"
        size="small"
      />

      <el-select
        v-model="filterData.dateQueryField"
        filterable
        placeholder="What date would you like to filter?"
        name="tags"
        class="license-filter-input-element"
        size="small"
      >
        <el-option
          v-for="item in dateFilterOpts"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>
      <el-date-picker
        v-model="dateValue"
        type="daterange"
        range-separator="-"
        start-placeholder="Start date"
        end-placeholder="End date"
        size="small"
      >
      </el-date-picker>

      <span class="license-filter-input-label">Tags</span>
      <el-select
        v-model="filterData.tags"
        filterable
        clearable
        remote
        placeholder="Please enter a tag"
        name="tags"
        :remote-method="getTags"
        :loading="tagsLoading"
        class="license-filter-input-element"
        size="small"
      >
        <el-option v-for="item in tagsOptions" :key="item.id" :label="item.val" :value="item.val" />
      </el-select>
      <span class="license-filter-input-label">License Type</span>
      <el-select v-model="filterData.model" placeholder="Select" class="license-filter-input-element" size="small">
        <el-option
          v-for="item in licenseTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        >
        </el-option>
      </el-select>
      <span class="license-filter-input-label">Lifetime Info</span>
      <el-select
        v-model="filterData.lifetimeStatuses"
        multiple
        placeholder="Please enter a status"
        name="tags"
        class="license-filter-input-element"
        size="small"
      >
        <el-option
          v-for="item in lifetimeStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>

      <!-- <span class="license-filter-input-label">Use Status</span>
      <el-select
        v-model="filterData.useStatuses"
        multiple
        placeholder="Please enter a status"
        name="tags"
        class="license-filter-input-element"
        size="small"
      >
        <el-option
          v-for="item in useStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>-->
      <span class="license-filter-input-label">Connection Info</span>
      <el-select
        v-model="filterData.activenessStatuses"
        multiple
        placeholder="Please enter a status"
        name="tags"
        class="license-filter-input-element"
        size="small"
      >
        <el-option
          v-for="item in activenessStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        />
      </el-select>
    </div>
    <div class="filter-action-buttons">
      <div style="text-align: left; margin: 0">
        <el-button type="secondary" size="small" @click="resetFilter()">Reset Filter</el-button>
        <el-button type="secondary" size="small" @click="closePanel()">Cancel</el-button>
      </div>
      <div style="text-align: right; margin: 0">
        <el-button type="primary" size="small" @click="applyFilter()">Apply Filter</el-button>
      </div>
    </div>
    <el-button slot="reference">
      <fa-icon icon="filter"></fa-icon>
    </el-button>
  </el-popover>
</template>

<script>
import dayjs from 'dayjs'
import { TagsService } from '@/api/Services/License/TagsService'
import { LicenseFilteredRequestDto } from '@/api/Services/License/dto/request/license-filtered-request.dto'
import { LicenseDateFilterTypeEnum } from '@/api/Services/License/enums/license-date-filter-type.enum'

export default {
  name: 'FilterLicensePopover',

  data() {
    return {
      visible: false,
      dateValue: '',
      filterData: {
        mainQueryField: 1,
        dateQueryField: 1,
        mainQuery: '',
        minDate: null,
        maxDate: null,
        tags: [],
        lifetimeStatuses: [],
        useStatuses: [],
        activenessStatuses: [],
        model: 0,
      },
      mainFilterOpts: [
        {
          value: 1,
          label: 'License Key',
          disabled: false,
        },
        /*{
          value: 2,
          label: 'Contact Name',
          disabled: true,
        },*/
        {
          value: 3,
          label: 'Contact Email',
          disabled: false,
        },
        {
          value: 4,
          label: 'Company Name',
          disabled: false,
        },
        /*{
          value: 5,
          label: 'Created By',
          disabled: true,
        },*/
      ],
      dateFilterOpts: [
        {
          value: 1,
          label: 'Created On',
          disabled: false,
        },
        /* {
          value: 2,
          label: 'Start Date',
          disabled: false,
        },*/
        {
          value: 3,
          label: 'Expiration Date',
          disabled: false,
        },
        {
          value: 4,
          label: 'Activated On',
          disabled: false,
        },
      ],
      licenseTypeOptions: [
        {
          value: 0,
          label: 'All',
          disabled: false,
        },
        {
          value: 1,
          label: 'Enterprise (and MSOC)',
          disabled: false,
        },
        {
          value: 2,
          label: 'IR (FIS)',
          disabled: false,
        },
        {
          value: 3,
          label: 'MSS',
          disabled: true,
        },
      ],
      licenseStatusOpts: [
        {
          value: 1,
          label: 'Started',
          disabled: false,
        },
        {
          value: 2,
          label: 'Not Started Yet',
          disabled: false,
        },
        {
          value: 3,
          label: 'Activated',
          disabled: false,
        },
        {
          value: 4,
          label: 'Not Activated Yet',
          disabled: false,
        },
        {
          value: 5,
          label: 'Expired',
          disabled: false,
        },
        {
          value: 6,
          label: 'Not Expired Yet',
          disabled: false,
        },
        {
          value: 7,
          label: 'Enabled',
          disabled: true,
        },
        {
          value: 8,
          label: 'Disabled',
          disabled: true,
        },
      ],
      lifetimeStatusOptions: [
        {
          value: 'pending',
          label: 'Pending',
          disabled: false,
        },
        {
          value: 'active',
          label: 'Active',
          disabled: false,
        },
        {
          value: 'expired',
          label: 'Expired',
          disabled: false,
        },
        {
          value: 'almost-expired',
          label: 'Almost-Expired',
          disabled: false,
        },
      ],
      useStatusOptions: [
        {
          value: 'pending',
          label: 'Pending',
          disabled: false,
        },
        {
          value: 'active',
          label: 'Active',
          disabled: false,
        },
        {
          value: 'full',
          label: 'Full',
          disabled: false,
        },
        {
          value: 'almost-full',
          label: 'Almost-Full',
          disabled: false,
        },
        {
          value: 'N/A',
          label: 'N/A',
          disabled: false,
        },
      ],
      activenessStatusOptions: [
        {
          value: 'active',
          label: 'Active',
          disabled: false,
        },
        {
          value: 'inactive',
          label: 'Inactive',
          disabled: false,
        },
        {
          value: 'N/A',
          label: 'N/A',
          disabled: false,
        },
      ],
      tagsLoading: false,
      tagsOptions: [],
    }
  },

  methods: {
    getSearchPlaceHolder() {
      switch (this.filterData.mainQueryField) {
        case 1:
          return 'Search License Key'
        case 2:
          return "Search Contact Name (Customer's point of contact)"
        case 3:
          return "Search Contact Email (Customer's point of contact)"
        case 4:
          return 'Search Company Name'
        case 5:
          return 'Search Created By'
      }
    },

    getTags(query) {
      if (query !== '') {
        this.tagsLoading = true

        this.$request(async () => {
          const { data } = await TagsService.getTags(query)
          this.tagsLoading = false
          if (data.success) {
            this.tagsOptions = data.result
              .filter((item) => {
                return item.toLowerCase().indexOf(query.toLowerCase()) > -1
              })
              .map((c) => {
                const id = this.getHash(c)
                return { id: id, val: c }
              })
          }
        })
      } else {
        this.options = []
      }
    },

    getHash(input) {
      let hash = 0,
        len = input.length
      for (let i = 0; i < len; i++) {
        hash = (hash << 5) - hash + input.charCodeAt(i)
        hash |= 0 // to 32bit integer
      }
      return hash
    },

    closePanel() {
      this.visible = false
    },

    resetFilterObject() {
      this.filterData = {
        mainQueryField: 1,
        dateQueryField: 1,
        mainQuery: '',
        minDate: null,
        maxDate: null,
        tags: [],
        lifetimeStatuses: [],
        activenessStatuses: [],
        useStatuses: [],
        model: 0,
      }
      this.dateValue = ''
    },

    updateQuery() {
      if (this.query.tag && this.query.tag !== '') {
        this.filterData.tags = []
        this.filterData.tags.push(this.query.tag)
      } else {
        this.filterData.tags = []
      }
    },

    resetFilter() {
      this.$emit('queryReady', {})
      this.resetFilterObject()
      this.closePanel()
    },

    applyFilter() {
      const requestObject = new LicenseFilteredRequestDto()
      if (!Array.isArray(this.filterData.tags)) {
        requestObject.tag = this.filterData.tags
      }

      requestObject.lifetimeStatuses = this.filterData.lifetimeStatuses
      requestObject.useStatuses = this.filterData.useStatuses
      requestObject.activenessStatuses = this.filterData.activenessStatuses

      if (this.filterData.model === 0) {
        //requestObject.types.push(LicenseTypeEnum.IR, LicenseTypeEnum.Enterprise, LicenseTypeEnum.MSS);
      } else {
        requestObject.types.push(this.filterData.model)
      }
      if (this.filterData.minDate) {
        switch (this.filterData.dateQueryField) {
          case 1: {
            requestObject.dateFilterType = LicenseDateFilterTypeEnum.Created
            break
          }
          case 2: {
            console.error('Not supported enum')
            break
          }
          case 3: {
            requestObject.dateFilterType = LicenseDateFilterTypeEnum.Expired
            break
          }
          case 4: {
            requestObject.dateFilterType = LicenseDateFilterTypeEnum.Activated
            break
          }
        }
        requestObject.minDate = this.filterData.minDate
        requestObject.maxDate = this.filterData.maxDate
      }

      if (this.filterData.mainQuery) {
        switch (this.filterData.mainQueryField) {
          case 1: {
            requestObject.key = this.filterData.mainQuery
            break
          }
          case 2: {
            console.error('Not supported enum')
            break
          }
          case 3: {
            requestObject.contactEmail = this.filterData.mainQuery
            break
          }
          case 4: {
            requestObject.companyName = this.filterData.mainQuery
            break
          }
          case 5: {
            console.error('Not supported enum')
            break
          }
        }
      }
      this.$emit('queryReady', requestObject)
      //this.resetFilterObject();
      this.closePanel()
    },
    removeDuplicates(arr) {
      return [...new Set(arr)]
    },
  },

  watch: {
    dateValue: function () {
      this.filterData.minDate = dayjs(this.dateValue[0])
      this.filterData.maxDate = dayjs(this.dateValue[1])
    },
    query: function () {
      this.updateQuery()
    },
  },

  props: {
    query: Object,
  },
}
</script>

<style lang="scss">
.filter-query-grid {
  display: grid;
  width: 90%;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  gap: 15px 10px;
  justify-items: stretch;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.license-filter-input-label {
  font-weight: bold;
}

.license-filter-input-label::after {
  content: ':';
}

.license-filter-input-element {
  width: 100%;
}

.license-filter-input-element.el-date-editor.el-input {
  width: 100%;
}

.filter-action-buttons {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 20px;
}

.license-filter-header {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdfe5;
  margin-bottom: 20px;
}
</style>
